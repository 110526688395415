import {
  diffInSeconds,
  duration,
  formatDate,
  formatPrice,
  isValidDate,
} from "../helpers/common";
import get from "lodash/get";

const marketMixin = {
  methods: {
    marketName(market) {
      return get(market, "market.role.name", "");
    },

    showDelivery(market) {
      const { mic } = market;
      if (mic && mic !== null) {
        const deliveryDate = new Date(mic.next_delivery);
        return (
          mic.island.i_type === 3 &&
          mic.next_delivery !== null &&
          isValidDate(deliveryDate)
        );
      }
      return false;
    },

    deliveryDate(market) {
      const { mic } = market;
      if (mic && mic !== null) {
        const date = mic.next_delivery;
        return formatDate(new Date(date), "dd-MM-yyyy");
      }
    },

    showPickup(market) {
      const { mic } = market;
      if (mic && mic !== null) {
        const deliveryDate = new Date(mic.next_delivery);
        return (
          (mic.island.i_type === 1 || mic.island.i_type === 2) &&
          mic.next_delivery !== null &&
          isValidDate(deliveryDate)
        );
      }
      return false;
    },

    pickupDate(market) {
      const { mic } = market;
      if (mic && mic !== null) {
        const date = mic.next_delivery;
        return formatDate(new Date(date), "dd-MM-yyyy");
      }
    },

    timelimitToOrder(market) {
      const { mic } = market;
      if (mic && mic !== null) {
        const timelimitDate = new Date(mic.next_timelimit);
        if (mic.next_delivery !== null && isValidDate(timelimitDate)) {
          const diff = diffInSeconds(new Date(), timelimitDate);
          return duration(diff);
        }
        return "--";
      }
    },

    minAmountToOrder(market, currencySymbol) {
      const { mic } = market;
      if (mic && mic !== null && mic.order_min) {
        return formatPrice(mic.order_min, currencySymbol).format();
      }
      return "--";
    },

    marketHaveLogo(market) {
      return get(market, "market.role.logo_square", null);
    },

    marketLogoThumb(market) {
      return get(market, "market.role.logo_square.image_thumb", "");
    },
  },
};

export default marketMixin;
